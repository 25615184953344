// v1.0.4 06-01-2025
"use client";

// React
import React, { useEffect, useState } from "react";

// pathname
import { usePathname } from "next/navigation";

// Authentification
import {
  logout,
  setAutoLogout,
} from "@phpcreation/frontend-utils-react-nextjs-bundle/utils/helpers";
import cookie from "js-cookie";

// i18n
import { useLocale } from "next-intl";

// Context
import { GetEnvironment } from "@/lib/GetEnvironment";

// Error handler
import { errorHandler } from "@phpcreation/frontend-utils-react-nextjs-bundle/utils";

type AuthClientProps = {
  children: React.ReactNode;
};

const pagesNotNeedingAuth = ["support", "status"];
const AuthClient: React.FC<AuthClientProps> = ({ children }) => {
  const [isIframe, setIsIframe] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [pageDoesntNeedsAuth, setPageDoesntNeedsAuth] = useState(false);
  const [loading, setLoading] = useState(true);
  const locale = useLocale();
  const pathName = usePathname();

  // Define the message handler function
  const handleMessage = (message: MessageEvent) => {
    const originList = message.origin.split(".");
    // Check the origin for security
    if (
      (originList[originList.length - 3] !== "login" &&
        originList[originList.length - 2] !== "phpr" &&
        originList[originList.length - 1] !== "link") ||
      message.origin.indexOf("localhost") !== -1
    ) {
      errorHandler(
        new Error("Blocked due to untrusted origin!"),
        `Untrusted origin : ${message.origin}`
      );
      return;
    }

    try {
      // Parse the message data safely
      const data = JSON.parse(message.data);
      const token = data.token;

      const preferencesApp = localStorage.getItem("preferences");

      if (
        JSON.stringify(data) !== "{}" &&
        data.preferences &&
        (!preferencesApp || JSON.stringify(preferencesApp) === "{}")
      ) {
        localStorage.setItem("preferences", JSON.stringify(data.preferences));
      }

      // If userId is present, call the function to handle the data
      if (token) {
        cookie.set("currentUser", token, {
          expires: 1 / 24,
        });
        setAutoLogout("TENANT", token);
      }
    } catch (error: any) {
      errorHandler(error, "Error parsing message data");
    }
  };

  useEffect(() => {
    // Add the event listener
    if (window.self !== window.top) {
      const trustedDomains = ["login.phpr.link"]; // Trusted domain(s)
      const referrer = new URL(document.referrer); // Get parent URL

      // Check if the parent is a trusted domain or the app itself
      const isTrusted =
        trustedDomains.some((domain) => referrer.hostname.endsWith(domain)) ||
        referrer.origin === window.location.origin;

      if (!isTrusted) {
        // Untrusted frames
        errorHandler(
          new Error("Blocked due to untrusted framing!"),
          `Untrusted parent : ${referrer.origin}`
        );
        document.body.innerHTML = ""; // Optionally clear content
      } else {
        console.log("Trusted source. (Punch)");
      }

      const tenant = "TENANT";
      const environment =
        GetEnvironment(window.location.href) === "prod"
          ? "."
          : `.${GetEnvironment(window.location.href)}.`;

      window.addEventListener("message", handleMessage);

      window.parent.postMessage(
        JSON.stringify({
          status: "ready",
          id: process.env.NEXT_PUBLIC_APP_ID || "punch",
        }), // Replace with your app's ID in the .env file
        `https://${tenant}${environment}${
          process.env.NEXT_PUBLIC_AUTH_DOMAIN || "login.phpr.link"
        }` // Replace with your login app's origin
      );

      setIsIframe(true);
    } else {
      const token = cookie.get("currentUser");
      if (token) {
        setAutoLogout("TENANT", token);
        setIsAuthenticated(true);
      } else {
        errorHandler(new Error("No token found"), "No token found");
        logout(locale);
      }
    }

    setLoading(false);

    return () => {
      if (window.self !== window.top) {
        window.removeEventListener("message", handleMessage);
      }
    };
  }, []);

  useEffect(() => {
    if (pagesNotNeedingAuth.some((page) => pathName.includes(page))) {
      setPageDoesntNeedsAuth(true);
    }

    const token = cookie.get("currentUser");
    if (!token) {
      logout(locale);
    }
  }, [pathName]);

  if ((!pageDoesntNeedsAuth && !isAuthenticated) || loading || isIframe) {
    return null;
  }

  return <>{children}</>;
};

export default AuthClient;
